<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li  v-permission="['streamadd']">
					<el-button @click="bindadd('新增')" color="#00ad79" style="color: white" class="el-white" size="mini">
						新增
					</el-button>
				</li>
				<li v-permission="['streamdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入姓名">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.tel" placeholder="请输入手机号码">
					</el-input>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.avatar">
							<img src="../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="village_name" label="村" />
				<el-table-column property="name" label="姓名" />
				<el-table-column property="industry" label="所从事行业" />
				<el-table-column property="temporary_address" label="暂住地址" />
				<el-table-column property="tel" label="联系电话" />
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="300">
					<template #default="scope">
						<el-button v-permission="['streamdetails']" size="mini"
							@click="$router.push({path:'/partyOrder',query:{name:scope.row.name}})">党费缴纳详情</el-button>
						<el-button v-permission="['streamru']" size="mini" @click="binset('流入',1,scope.row.id,scope.row.party_member_id)">设置流入</el-button>
						<el-button v-permission="['streamchu']" size="mini" @click="binset('流出',2,scope.row.id,scope.row.party_member_id)">设置流出</el-button>
						<el-button v-permission="['streamdele']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 新增/编辑弹窗 -->
		<add ref="add" @SubmitForm="getList"></add>
		<!-- 设置 -->
		<sets ref="sets"></sets>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/stream";
	import add from './add.vue'
	import sets from './set/index.vue'
	export default {
		components: {
			add,
			sets
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					tel: '',
					page: 1,
					limit: 15,
					is_duizhang: 1,
					village_id: ''
				},
				tableData: [],
				idlist: [],
				cunlist: []
			}
		},
		created() {
			this.getList()
			this.getcun()
		},
		methods: {
			// 设置
			binset(val,type,id,party_member_id) {
				this.$refs.sets.show(val,type,id,party_member_id)
			},
			// 获取村列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 新增
			bindadd(val, id) {
				this.$refs.add.show(val, id)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.is_duizhang = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>
