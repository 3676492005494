<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="选择党员">
						<span class="xx">*</span>
						<el-select multiple filterable clearable v-model="listId"
							placeholder="请选择党员(可搜索)" @change="change">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/stream";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					party_member_id: ''
				},
				list: [],
				listId:[]
			}
		},
		methods: {
			// 选中
			change(e) {
				this.listId  = e
			},
			Reset() {
				this.listId = []
				this.ruleForm.party_member_id = ""
			},
			// 获取党员
			getList() {
				this.$http.post(api.dlists, {
						page: 1,
						limit: 99999,
						identity: 3
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.data
						}
					})
			},
			//新增/编辑
			SubmitForm() {
				this.ruleForm.party_member_id = this.listId.length>0?this.listId.join(","):''
				if (this.ruleForm.party_member_id === '') {
					ElMessage({
						message: '请选择党员',
						type: 'warning',
					})
				} else {
					this.$http.post(api.add, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '添加成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}
			},

			// 显示隐藏
			show(val, id) {
				this.title = val
				this.getList()
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>