import request from './request'

const api = {
	// 获取列表
	lists: '/partymemberinflow/lists',
	// 添加
	add: '/partymemberinflow/add',
	// 删除
	dele: '/partymemberinflow/del',
	// 编辑
	edit: '/partymemberinflow/update',
	// 详情
	info: '/partymemberinflow/read',
}

export {
	request,
	api
}
