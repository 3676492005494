<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item :label="text + '原因'">
						<span class="xx">*</span>
						<el-input clearable type='textarea' v-model="ruleForm.reason"
							:placeholder="'请输入' + text + '原因'"></el-input>
					</el-form-item>

					<el-form-item :label="text + '地址'">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.address" :placeholder="'请输入' + text + '地址'"></el-input>
					</el-form-item>
					<el-form-item :label="text + '时间'">
						<span class="xx">*</span>
						<el-date-picker @change="change" v-model="time" type="datetime" :placeholder="'请输入' + text + '时间'" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/streamset";
	import content from '@/components/content.vue'
	export default {
		props: ['text'],
		components: {
			content
		},
		data() {
			return {
				outerVisible: false,
				title: '新增',
				list: [],
				time:'',
				ruleForm: {
					party_member_id: '',
					party_member_float_id: '',
					address: '',
					dates: '',
					reason: '',
					type: 1
				}
			}
		},
		methods: {
			// 时间
			change(e) {
				if (e) {
					this.ruleForm.dates = this.$Moment(e).format('YYYY/MM/DD HH:mm:ss')
				} else {
					this.ruleForm.dates = ""
				}
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.time = res.data.dates
						}
					})
			},
			// 显示隐藏
			show(val, id, party_member_float_id,type,party_member_id) {
				this.ruleForm.party_member_float_id = party_member_float_id
				this.ruleForm.party_member_id = party_member_id
				this.ruleForm.type = type
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.time = ""
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.dates = this.ruleForm.dates ? Math.round(new Date(this.ruleForm.dates) /
					1000) : ''
				if (this.ruleForm.reason === '') {
					ElMessage({
						message: '请输入' + this.text + '原因',
						type: 'warning',
					})
				} else if (this.ruleForm.address === '') {
					ElMessage({
						message: '请输入' + this.text + '地址',
						type: 'warning',
					})
				} else if (this.ruleForm.dates === '') {
					ElMessage({
						message: '请输入' + this.text + '时间',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
