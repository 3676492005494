import request from './request'

const api = {
	// 获取党员列表
	dlists: '/partymember/lists',
	// 获取列表
	lists: '/partymemberfloat/lists',
	// 添加
	add: '/partymemberfloat/add',
	// 删除
	dele: '/partymemberfloat/del',
	// 编辑
	edit: '/partymemberfloat/update',
	// 详情
	info: '/partymemberfloat/read',
	// 村
	cunlist: '/village/lists',
	
}

export {
	request,
	api
}
